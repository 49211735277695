define('songbox/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax', 'songbox/config/environment'], function (exports, _ember, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      service = _ember.default.inject.service;
  var _ENV$api = _environment.default.api,
      host = _ENV$api.host,
      namespace = _ENV$api.namespace;
  exports.default = _ajax.default.extend({
    session: service(),

    host: host,
    namespace: namespace,

    headers: computed('session.data', {
      get: function get() {
        var headers = {
          'Accept': 'application/vnd.api+json'
        };
        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        return headers;
      }
    })
  });
});