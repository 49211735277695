define('songbox/utils/parsers', ['exports', 'songbox/utils/parsers/opensong'], function (exports, _opensong) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var parsers = {
    opensong: _opensong.default
  };

  exports.default = parsers;
});