define('songbox/pods/auth/register/route', ['exports', 'ember', 'songbox/mixins/routes/model-changeset', 'songbox/validations/user'], function (exports, _ember, _modelChangeset, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      service = _ember.default.inject.service,
      camelize = _ember.default.String.camelize;
  exports.default = Route.extend(_modelChangeset.default, {
    validator: _user.default,

    i18n: service(),
    session: service(),
    flashMessages: service(),

    model: function model() {
      return {
        modelName: 'user'
      };
    },


    actions: {
      doRegister: function doRegister(changeset) {
        var _this = this;

        var user = this.store.createRecord('user', changeset.get('change'));
        return user.save().then(function () {
          _this.transitionTo('auth.login');
          _this.get('flashMessages').success('Registered! Please login now');
        }).catch(function (resp) {
          _this.store.unloadRecord(user);
          resp.errors.forEach(function (error) {
            var attribute = camelize(error.source.pointer.split('/')[3]);
            var message = error.detail;
            changeset.pushErrors(attribute, message);
          });
        });
      }
    }
  });
});