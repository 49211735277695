define('songbox/pods/components/item-list/list-items/list-item/component', ['exports', 'ember', 'ember-sortable/mixins/sortable-item'], function (exports, _ember, _sortableItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _ember.default.Component.extend(_sortableItem.default, {
    tagName: 'li',

    classNameBindings: ['isSelected:active'],

    isSelected: computed('model.id', 'selected.id', function () {
      return this.get('model.id') === this.get('selected.id');
    })
  });
});