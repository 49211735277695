define('songbox/services/pubsub', ['exports', 'phoenix/services/phoenix-socket', 'ember', 'songbox/config/environment'], function (exports, _phoenixSocket, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = _ember.default.assert,
      equal = _ember.default.computed.equal,
      service = _ember.default.inject.service,
      RSVP = _ember.default.RSVP,
      Service = _ember.default.Service;


  var PhoenixSocketService = _phoenixSocket.default.extend({
    session: service(),
    flashMessages: service(),

    statusCode: 0,
    statusMessage: '',

    isSuccess: equal('statusCode', 2),
    isInfo: equal('statusCode', 1),
    isDanger: equal('statusCode', 0),

    init: function init() {
      var _this = this;

      // You may listen to open, "close" and "error"
      this.on('open', function () {
        _this._success('Connection established!');
      });
      this.on('close', function () {
        _this._danger('Connection lost!');
      });
      this.on('error', function () {
        _this._danger('Error connecting!');
      });
    },
    connectUser: function connectUser() {
      var token = this.get('session.data').authenticated.access_token;
      var params = { token: token };
      return this.connect(_environment.default.ws.host + '/socket/user', { params: params });
    },
    connectViewer: function connectViewer() {
      return this.connect(_environment.default.ws.host + '/socket/viewer');
    },
    joinChannel: function joinChannel(name, params) {
      var _this2 = this;

      var socket = this.get('socket');
      assert('must connect to a socket first', socket);

      return new RSVP.Promise(function (resolve, reject) {
        var channel = socket.channel(name, params);
        channel.join().receive('ok', function () {
          _this2._success('Ready to broadcast!');
          resolve(channel);
        }).receive('error', function (_ref) {
          var reason = _ref.reason;

          _this2._danger('Cannot broadcast!');
          reject(reason);
        }).receive('timeout', function () {
          _this2._info('Networking issue. Still waiting...');
        });
      });
    },
    _success: function _success(message) {
      this.get('flashMessages').success(message);
      this.setProperties({ statusCode: 2, statusMessage: message });
    },
    _info: function _info(message) {
      this.get('flashMessages').info(message);
      this.setProperties({ statusCode: 1, statusMessage: message });
    },
    _danger: function _danger(message) {
      this.get('flashMessages').danger(message);
      this.setProperties({ statusCode: 0, statusMessage: message });
    }
  });

  // DummySocketService used while dev with `ember-cli-mirage` or while testing.
  // (better to inject somewhere?)
  var DummySocketService = Service.extend({
    connectUser: function connectUser() {
      return new RSVP.resolve({});
    },
    connectViewer: function connectViewer() {
      return new RSVP.resolve({});
    },
    joinChannel: function joinChannel() /*name, params*/{
      return new RSVP.resolve({
        push: function push() {},
        on: function on() {}
      });
    }
  });

  var SocketService = void 0;
  if (_environment.default['ember-cli-mirage'].enabled || _environment.default.environment === 'test') {
    SocketService = DummySocketService;
  } else {
    SocketService = PhoenixSocketService;
  }

  exports.default = SocketService;
});