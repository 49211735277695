define('songbox/helpers/svg-icon', ['exports', 'ember-string', 'ember-svg-jar/utils/make-helper', 'ember-svg-jar/utils/make-svg', 'songbox/inline-assets'], function (exports, _emberString, _makeHelper, _makeSvg, _inlineAssets) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.svgIcon = svgIcon;
  function svgIcon(assetId) {
    var attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var svgAttrs = Object.assign({ class: 'icon' }, attrs);
    return (0, _emberString.htmlSafe)((0, _makeSvg.default)(assetId, svgAttrs, _inlineAssets.default));
  }

  exports.default = (0, _makeHelper.default)(svgIcon);
});