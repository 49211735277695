define('songbox/pods/auth/login/route', ['exports', 'ember', 'songbox/mixins/routes/model-changeset', 'songbox/validations/user-login'], function (exports, _ember, _modelChangeset, _userLogin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      service = _ember.default.inject.service;
  exports.default = Route.extend(_modelChangeset.default, {
    validator: _userLogin.default,

    i18n: service(),
    session: service(),
    flashMessages: service(),

    model: function model() {
      return {
        modelName: 'user'
      };
    },


    actions: {
      doLogin: function doLogin(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            return _this.get('session').authenticate('authenticator:songbox', changeset.get('email'), changeset.get('password')).then(function () {
              var message = _this.get('i18n').t('auth.login.flash-200');
              _this.get('flashMessages').success(message);
            }).catch(function (response) {
              var errors = response.errors;


              if (errors.mapBy('code').includes(401)) {
                var message = _this.get('i18n').t('auth.login.flash-401');
                _this.get('flashMessages').danger(message);
              } else {
                var _message = _this.get('i18n').t('auth.login.flash-500');
                _this.get('flashMessages').danger(_message);
              }
            });
          }
        });
      }
    }
  });
});