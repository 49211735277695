define('songbox/mixins/routes/model-changeset', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations'], function (exports, _ember, _emberChangeset, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var validator = this.validator;
      var changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validator), validator);
      controller.set('changeset', changeset);
    }
  });
});