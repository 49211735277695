define("songbox/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // models
    "song": {
      "one": '1 song',
      "other": '{{count}} songs'
    },
    "song.title": "Title",
    "song.author": "Author",
    "song.key": "Key",
    "song.tempo": "Tempo",
    "song.time": "Time",
    "song.license": "License",
    "song.ccli": "CCLI",
    "song.text": "Text",
    "song.files": "Files", // used for import
    "list": {
      "one": '1 list',
      "other": '{{count}} lists'
    },
    "list.name": "Name",
    "user": {
      "email": "Email",
      "password": "Password",
      "password-confirmation": "Confirmation"
    },
    // routes
    "auth.login": {
      "button": "Login",
      "flash-200": "Successfully logged in!",
      "flash-401": "There was a problem with your username or password, please try again",
      "flash-500": "Server Error"
    },
    "auth.register": {
      "button": "Register"
    },
    "app": {
      "link-songs": "Songs",
      "link-lists": "Lists",
      "link-account": "Account"
    },
    "songs": {
      "link-edit": "Edit",
      "link-new": "New",
      "link-import": "Import",
      "import.header": "Import"
    },
    "songs.index": {
      "text": "Select a song"
    },
    "songs.new": {
      "header": "Add a new Song"
    },
    "song.index": {
      "link-edit": "Edit"
    },
    "song.edit": {
      "header": "Edit Song \"{{title}}\""
    },
    "lists": {
      "header": "Lists",
      "link-new": "New",
      "link-edit": "Edit"
    },
    "lists.new": {
      "header": "Add a new List"
    },
    "list.edit": {
      "header": "Edit List \"{{name}}\""
    }
  };
});