define('songbox/session-stores/application', ['exports', 'ember-simple-auth/session-stores/cookie'], function (exports, _cookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _cookie.default.extend({
    cookieName: 'songbox-session',
    cookieExpirationTime: 60 * 60 * 24 * 365 // expire cookie after 1 year
  });
});