define('songbox/adapters/user', ['exports', 'songbox/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord() /*modelName, snapshot*/{
      return this._super.apply(this, arguments).replace('users', 'register');
    },
    urlForUpdateRecord: function urlForUpdateRecord() /*modelName, snapshot*/{
      return this._super.apply(this, arguments).replace(/[0-9]+$/, 'current');
    }
  });
});