define('songbox/pods/components/song-sheet/component', ['exports', 'ember', 'songbox/utils/parsers'], function (exports, _ember, _parsers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _ember.default.Component.extend({
    song: null,

    showChords: true,
    showSectionHeaders: true,
    showMultiColumn: false,

    sections: computed('song.text', 'song.format', function () {
      var parser = _parsers.default[this.get('song.format') || 'opensong'];
      return parser(this.get('song.text'));
    })
  });
});