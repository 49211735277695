define('songbox/pods/components/modal-target/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    modalAnimation: modalAnimation
  });


  function modalAnimation() {
    return this.lookup('explode').call(this, {
      pick: '.modal-background',
      use: ['fade', { maxOpacity: 0.5 }]
    }, {
      pick: '.modal-dialog',
      use: 'scale'
    });
  }
});