define('songbox/pods/components/settings-switch/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      service = _ember.default.inject.service;


  var SettingsSwitch = _ember.default.Component.extend({
    settings: service(),

    key: null,

    setting: computed('key', {
      get: function get() /*key*/{
        var key = this.get('key');
        return this.get('settings.' + key);
      },
      set: function set(_key, value) {
        var key = this.get('key');
        this.set('settings.' + key, value);
        return value;
      }
    })

  });

  SettingsSwitch.reopenClass({
    positionalParams: ['key']
  });

  exports.default = SettingsSwitch;
});