define('songbox/pods/list/item/index/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  exports.default = _ember.default.Route.extend({
    pubsub: service(),

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.controllerFor('list').setProperties({ selected: model.item });

      // share song
      var channel = this.controllerFor('app').get('model.channel');
      var song = model.item.get('song');
      if (channel) {
        channel.push('share', song.getProperties('title', 'text'));
      }
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.controllerFor('list').setProperties({ selected: null });
      }
    }
  });
});