define('songbox/pods/components/item-list/list-header/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ListHeaderComponent = _ember.default.Component.extend({
    tagName: 'h1'
  });

  ListHeaderComponent.reopenClass({
    positionalParams: ['text']
  });

  exports.default = ListHeaderComponent;
});