define('songbox/router', ['exports', 'ember', 'songbox/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = _ember.default.get,
      service = _ember.default.inject.service,
      scheduleOnce = _ember.default.run.scheduleOnce;


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    metrics: service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');
        get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {

    this.route('auth', function () {
      this.route('login');
      this.route('register');
    });

    this.route('viewer', {
      path: 'v'
    }, function () {
      this.route('not_found');
      this.route('room', {
        path: ':room_id'
      }, function () {});
    });

    this.route('app', {
      path: 'a'
    }, function () {

      this.route('songs', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('import');

        this.route('song', {
          path: ':song_id',
          resetNamespace: true
        }, function () {
          this.route('edit');
        });
      });

      this.route('lists', {
        resetNamespace: true
      }, function () {
        this.route('new');

        this.route('list', {
          path: ':list_id',
          resetNamespace: true
        }, function () {
          this.route('add');

          this.route('item', {
            path: ':position'
          }, function () {});
        });
      });
    });
  });

  exports.default = Router;
});