define('songbox/mixins/routes/dirty-changeset', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Mixin.create({

    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        var changeset = this.get('controller.changeset');

        if (changeset.get('isPristine')) {
          return true;
        }

        var shouldDiscard = window.confirm('Do you want to discard your changes?');

        if (!shouldDiscard) {
          return transition.abort();
        }
      }
    }

  });
});