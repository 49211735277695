define('songbox/pods/components/item-list/list-items/list-item/item-text/item-title/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TextComponent = _ember.default.Component.extend({});

  TextComponent.reopenClass({
    positionalParams: ['text']
  });

  exports.default = TextComponent;
});