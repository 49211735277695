define('songbox/initializers/route-classes', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // https://dockyard.com/blog/ember/2013/03/27/body-class-tags-in-ember
  function initialize() /*owner*/{
    _ember.default.Route.reopen({
      activate: function activate() {
        var cssClass = this.toCssClass();
        // you probably don't need the application class
        // to be added to the body
        if (cssClass === 'application') {
          return;
        }

        if (typeof FastBoot === "undefined") {
          _ember.default.$('body').addClass(cssClass);
        }
      },
      deactivate: function deactivate() {
        if (typeof FastBoot === "undefined") {
          _ember.default.$('body').removeClass(this.toCssClass());
        }
      },
      toCssClass: function toCssClass() {
        return this.routeName.replace(/\./g, '-').dasherize();
      }
    });
  }

  exports.default = {
    name: 'route-classes-browser',
    initialize: initialize
  };
});