define('songbox/pods/song/edit/route', ['exports', 'ember', 'songbox/mixins/routes/model-changeset', 'songbox/mixins/routes/dirty-changeset', 'songbox/validations/song'], function (exports, _ember, _modelChangeset, _dirtyChangeset, _song) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_modelChangeset.default, _dirtyChangeset.default, {
    validator: _song.default,

    actions: {
      save: function save(changeset) {
        var _this = this;

        changeset.save().then(function (song) {
          _this.transitionTo('song', song);
        });
      },
      show: function show(song) {
        this.transitionTo('song', song);
      }
    }
  });
});