define('songbox/components/form-fields/custom-field', ['exports', 'ember-form-for/components/form-fields/custom-field'], function (exports, _customField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _customField.default;
    }
  });
});