define('songbox/pods/components/broadcast-info/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  exports.default = _ember.default.Component.extend({
    pubsub: service(),
    current: service(),

    attributeBindings: ['pubsub.statusMessage:title'],
    classNameBindings: ['pubsub.isSuccess:success', 'pubsub.isInfo:info', 'pubsub.isDanger:danger']
  });
});