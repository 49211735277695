define('songbox/pods/viewer/room/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  exports.default = _ember.default.Controller.extend({
    settings: service(),

    queryParams: {
      showMenu: 'open'
    },
    showMenu: false

  });
});