define('songbox/pods/app/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  exports.default = _ember.default.Controller.extend({
    settings: service(),

    queryParams: {
      // NOTE: disable query-param for now
      //showMenu: 'open'
      editUser: 'account'
    },
    showMenu: true,
    editUser: false

  });
});