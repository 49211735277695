define('songbox/models/list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    name: attr('string'),

    listItems: hasMany('list_item')
  });
});