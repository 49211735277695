define("songbox/helpers/host-url", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hostUrl = hostUrl;
  function hostUrl() /*params, hash*/{
    if (window && window.location) {
      return window.location.origin;
    } else {
      return "";
    }
  }

  exports.default = _ember.default.Helper.helper(hostUrl);
});