define('songbox/models/song', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({

    // attributes
    title: attr('string'),
    author: attr('string'),
    key: attr('string'),
    tempo: attr('number'),
    time: attr('string'),

    text: attr('string'),
    format: attr('string', { defaultValue: 'opensong' }),

    license: attr('string'),
    ccli: attr('number')
  });
});