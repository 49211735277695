define('songbox/pods/list/add/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  exports.default = _ember.default.Route.extend({
    queryParams: {
      songId: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var songId = _ref.songId;

      var options = { reload: false, backgroundReload: true };

      return RSVP.hash({
        list: this.modelFor('list'),
        songs: this.store.findAll('song', options),
        song: songId && this.store.findRecord('song', songId)
      });
    },


    actions: {
      add: function add(list, song) {
        var item = this.store.createRecord('list-item', {
          list: list,
          song: song
        });
        return item.save();
      },
      select: function select(list, song) {
        this.transitionTo({ queryParams: { songId: song.get('id') } });
      }
    }
  });
});