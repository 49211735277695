define('songbox/pods/lists/new/route', ['exports', 'ember', 'songbox/mixins/routes/model-changeset', 'songbox/validations/list'], function (exports, _ember, _modelChangeset, _list) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_modelChangeset.default, {
    validator: _list.default,

    model: function model() {
      return {
        modelName: 'list' // used in ember-form-for
      };
    },


    actions: {
      save: function save(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            var list = _this.store.createRecord('list', changeset.get('change'));
            list.save().then(function () {
              _this.transitionTo('lists');
            });
          }
        });
      },
      show: function show() {
        this.transitionTo('lists');
      }
    }
  });
});