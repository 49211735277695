define('songbox/adapters/application', ['exports', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'songbox/config/environment'], function (exports, _jsonApi, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.api.host,
    namespace: _environment.default.api.namespace,
    authorizer: 'authorizer:oauth2'
  });
});