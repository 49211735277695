define('songbox/utils/promise-file-reader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;


  function readAs(file, as) {
    if (!(file instanceof Blob)) {
      throw new TypeError('Must be a File or Blob');
    }
    return new RSVP.Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function (e) {
        return resolve(e.target.result);
      };
      reader.onerror = function (e) {
        return reject('Error reading ' + file.name + ': ' + e.target.result);
      };
      reader['readAs' + as](file);
    });
  }

  var readAsDataURL = exports.readAsDataURL = function readAsDataURL(file) {
    return readAs(file, 'DataURL');
  };

  var readAsText = exports.readAsText = function readAsText(file) {
    return readAs(file, 'Text');
  };

  var readAsArrayBuffer = exports.readAsArrayBuffer = function readAsArrayBuffer(file) {
    return readAs(file, 'ArrayBuffer');
  };

  exports.default = {
    readAsDataURL: readAsDataURL, readAsText: readAsText, readAsArrayBuffer: readAsArrayBuffer
  };
});