define('songbox/validations/user', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    email: (0, _validators.validateFormat)({ type: 'email' }),
    password: (0, _validators.validateLength)({ min: 8, max: 24 }),
    passwordConfirmation: (0, _validators.validateConfirmation)({ on: 'password' })
  };
});