define('songbox/instance-initializers/form-for-initializer', ['exports', 'ember', 'songbox/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var merge = _ember.default.merge,
      set = _ember.default.set;


  var DEFAULT_CONFIG = {
    buttonClasses: ['form-button'],
    fieldClasses: ['form-field'],
    fieldHasErrorClasses: ['form-field--has-errors'],
    errorClasses: ['form-field--errors'],
    hintClasses: ['form-field--hint'],
    inputClasses: ['form-field--control'],
    labelClasses: ['form-field--label'],
    resetClasses: ['form-button--reset'],
    submitClasses: ['form-button--submit']
  };

  function initialize(application) {
    var formForConfig = merge(DEFAULT_CONFIG, _environment.default['ember-form-for']);
    var configService = application.lookup('service:ember-form-for/config');

    Object.keys(formForConfig).forEach(function (key) {
      set(configService, key, formForConfig[key]);
    });
  }

  exports.default = {
    name: 'form-for-initializer',
    initialize: initialize
  };
});