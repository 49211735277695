define('songbox/pods/components/x-toasts/x-toast/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      readOnly = _ember.default.computed.readOnly,
      getWithDefault = _ember.default.getWithDefault,
      _Ember$run = _ember.default.run,
      next = _Ember$run.next,
      cancel = _Ember$run.cancel;
  exports.default = _ember.default.Component.extend({
    classNames: ['material-toast', 'toast'],
    classNameBindings: ['active', 'exiting', 'color'],
    active: false,

    color: computed('content.type', function () {
      switch (this.get('content.type')) {
        case 'danger':
          return 'red darken-2 white-text';
        case 'warning':
          return 'yellow lighten-1 black-text';
        default:
          return '';
      }
    }),
    exiting: readOnly('content.exiting'),

    _destroyFlashMessage: function _destroyFlashMessage() {
      var flash = getWithDefault(this, 'content', false);
      if (flash) {
        flash.destroyMessage();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      // Very shortly after a message is created, add the "active"
      // class to it, so that we can use CSS animations for
      // the entry transition
      this._applyActiveClass = next(function () {
        _this.set('active', true);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super();
      // Prevent leaking
      this._destroyFlashMessage();
      // To be thorough, we will cancel any queued
      // task to add the "active" class (see: didInsertElement)
      if (this._applyActiveClass) {
        cancel(this._applyActiveClass);
      }
    }
  });
});