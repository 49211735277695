define('songbox/pods/components/list-songs-add-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    list: null,
    songs: [],

    actions: {
      addSong: function addSong(song) {
        this.get('onAdd')(song);
      },
      selectSong: function selectSong(song) {
        this.get('onSelect')(song);
      }
    }
  });
});