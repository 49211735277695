define('songbox/serializers/application', ['exports', 'ember-data/serializers/json-api', 'ember-inflector'], function (exports, _jsonApi, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    }
  });
});