define('songbox/pods/list/add/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    queryParams: {
      songId: 'preview'
    },
    songId: null
  });
});