define('songbox/helpers/song-section-header', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.songSectionHeader = songSectionHeader;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var isEmpty = _ember.default.isEmpty;
  function songSectionHeader(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        abbr = _ref2[0];

    var replacements = {
      'C': 'Chorus',
      'V': 'Verse',
      'B': 'Bridge',
      'T': 'Tag',
      'P': 'Pre-Chorus'
    };

    var regexp = new RegExp('^([' + Object.keys(replacements).join("") + '])(.*)$', "i");
    var abbArr = regexp.exec(abbr);

    if (isEmpty(abbArr)) {
      return abbr; // <- !!
    }

    // cleanup match
    abbArr.shift();
    if (isEmpty(abbArr[1])) {
      abbArr.pop();
    }

    // do replacement
    var char = abbArr[0].toUpperCase();
    abbArr[0] = replacements[char];

    /*
    # use i18n if available
    abbArr[0] = i18n.t "header.#{abbArr[0].toLowerCase()}" if i18n?
      */

    return abbArr.join(' ');
  }

  exports.default = _ember.default.Helper.helper(songSectionHeader);
});