define('songbox/initializers/service-injections', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'current', 'service:current');
  }

  exports.default = {
    name: 'service-injections',
    initialize: initialize
  };
});