define('songbox/initializers/local-settings', ['exports', 'songbox/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    // Depending on the context the first argument might be the registry, or it
    // might be the application.
    var application = arguments[1] || arguments[0];
    var _ENV$localSettings = _environment.default.localSettings,
        config = _ENV$localSettings === undefined ? {
      serializer: 'json',
      adapter: 'local-storage',
      prefix: 'emberApp/'
    } : _ENV$localSettings;


    application.register('config:local-settings', config, { instantiate: false });
    application.inject('service:local-settings', 'config', 'config:local-settings');
  }

  exports.default = {
    name: 'local-settings',
    initialize: initialize
  };
});