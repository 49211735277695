define('songbox/pods/viewer/room/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  exports.default = _ember.default.Route.extend({
    pubsub: service(),

    model: function model() {
      return {
        title: 'Please wait...',
        format: 'opensong',
        text: ''
      };
    },
    setupController: function setupController(controller /*, model*/) {
      if (typeof FastBoot === "undefined") {
        var pathElems = window.location.pathname.split('/');
        var roomId = pathElems[pathElems.length - 1];

        this.get('pubsub').connectViewer();
        this.get('pubsub').joinChannel('room:' + roomId, { type: 'Viewer' }).then(function (channel) {
          channel.on('share', function (resp) {
            controller.set('model', resp);
          });
        });
      }
    }
  });
});