define('songbox/pods/components/list-items-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    list: null,

    actions: {
      selectItem: function selectItem(item, index) {
        this.get('onSelect')(item, index);
      },
      removeItem: function removeItem(item) {
        this.get('onRemove')(item);
      },
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.get('onReorder')(itemModels, draggedModel);
      }
    }
  });
});