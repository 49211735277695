define('songbox/pods/components/lists-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    lists: [],

    actions: {
      selectList: function selectList(list) {
        this.get('onSelect')(list);
      },
      removeList: function removeList(list) {
        this.get('onRemove')(list);
      }
    }
  });
});