define('songbox/pods/components/item-list/list-filter/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      isEmpty = _ember.default.isEmpty;
  exports.default = _ember.default.Component.extend({
    items: [],

    searchTerm: '',
    filtered: computed('items.[]', 'searchTerm', function () {
      var term = this.get('searchTerm');
      var attr = this.get('attr');

      if (isEmpty(term)) {
        return this.get('items');
      }

      var regexp = new RegExp(term, 'i');
      return this.get('items').filter(function (item) {
        return regexp.test(item.get(attr));
      });
    }),

    actions: {
      clearSearch: function clearSearch() {
        this.set('searchTerm', '');
      }
    }
  });
});