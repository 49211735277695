define('songbox/pods/songs/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model() {
      return this.store.findAll('song');
    },


    actions: {
      select: function select(song) {
        return this.transitionTo('song', song);
      },
      remove: function remove(song) {
        return song.destroyRecord();
      }
    }
  });
});