define('songbox/pods/app/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-changeset', 'ember-changeset-validations', 'songbox/validations/user'], function (exports, _ember, _authenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      service = _ember.default.inject.service,
      RSVP = _ember.default.RSVP;
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {
    pubsub: service(),
    session: service(),
    current: service(),

    authenticationRoute: computed(function () {
      return 'auth.login';
    }),

    model: function model() {
      var _this = this;

      return this.get('current').load().then(function (user) {
        var channel = null;
        if (!_this.get('_isFastBoot')) {
          _this.get('pubsub').connectUser();
          var channelName = 'room:' + user.get('room.uid');
          channel = _this.get('pubsub').joinChannel(channelName);
        }
        return RSVP.hash({ user: user, channel: channel });
      }).catch(function () /*err*/{
        return _this.get('session').invalidate();
      });
    },


    // copied from 'AuthenticatedRouteMixin'
    afterModel: function afterModel(model, transition) {
      if (!this.get('session.isAuthenticated')) {
        if (!this.get('_isFastBoot')) {
          this.set('session.attemptedTransition', transition);
        }
        var authenticationRoute = this.get('authenticationRoute');
        return this.transitionTo(authenticationRoute);
      } else {
        return this._super.apply(this, arguments);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var validator = _user.default;
      var changeset = new _emberChangeset.default(model.user, (0, _emberChangesetValidations.default)(validator), validator);
      controller.set('changeset', changeset);
    },


    actions: {
      editUser: function editUser() /*user*/{
        this.transitionTo({ queryParams: { editUser: true } });
      },
      saveUser: function saveUser(changeset) {
        var _this2 = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            changeset.save().then(function () {
              changeset.rollback();
              _this2.transitionTo({ queryParams: { editUser: false } });
            });
          }
        });
      },
      cancelUser: function cancelUser(changeset) {
        changeset.rollback();
        this.transitionTo({ queryParams: { editUser: false } });
      },
      logout: function logout() {
        return this.get('session').invalidate();
      }
    }
  });
});