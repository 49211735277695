define('songbox/pods/songs/import/route', ['exports', 'ember', 'songbox/utils/promise-file-reader'], function (exports, _ember, _promiseFileReader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = _ember.default.$;
  exports.default = _ember.default.Route.extend({
    model: function model() {
      return {
        modelName: 'song' };
    },


    actions: {
      doImport: function doImport() {
        var _this = this;

        var fileList = $('input[type=file]')[0].files;

        Array.prototype.forEach.call(fileList, function (file) {
          switch (file.type) {
            case '':

              // opensong file
              (0, _promiseFileReader.readAsText)(file).then(function (fileContents) {
                var xmlDoc = $.parseXML(fileContents);
                var $xml = $(xmlDoc);

                var song = _this.store.createRecord('song');
                song.setProperties({
                  title: $xml.find('title').text(),
                  author: $xml.find('author').text(),
                  key: $xml.find('key').text(),
                  text: $xml.find('lyrics').text()
                });
                return song.save().catch(function () {
                  _this.store.unloadRecord(song);
                });
              });

              break;
            default:
              console.warn(file.type + ' not supported yet');
          }
        });
      },
      cancel: function cancel() {
        this.transitionTo('songs');
      }
    }
  });
});