define('songbox/services/local-settings', ['exports', 'ember-local-settings/services/local-settings'], function (exports, _localSettings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _localSettings.default;
    }
  });
});