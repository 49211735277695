define('songbox/services/current', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      readOnly = _ember.default.computed.readOnly,
      service = _ember.default.inject.service;
  exports.default = _ember.default.Service.extend({
    ajax: service(),
    store: service(),

    userId: null,

    user: computed('userId', function () {
      return this.get('store').peekRecord('user', this.get('userId'));
    }),
    room: readOnly('user.room'),

    load: function load() {
      var _this = this;

      return this.get('ajax').request('/users/current').then(function (json) {
        _this.get('store').pushPayload(json);
        _this.set('userId', json.data.id);
        return _this.get('user');
      });
    }
  });
});