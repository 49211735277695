define('songbox/pods/list/item/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service,
      RSVP = _ember.default.RSVP;
  exports.default = _ember.default.Route.extend({
    flashMessages: service(),

    model: function model(_ref) {
      var position = _ref.position;

      var list = this.modelFor('list');
      var item = list.get('listItems').sortBy('rank').objectAt(position - 1);

      return RSVP.hash({
        list: list,
        item: item
      });
    },
    afterModel: function afterModel(model) {
      if (!model.item) {
        this.get('flashMessages').danger('Item not found');
        this.transitionTo('list', model.list);
      }
    }
  });
});