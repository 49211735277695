define('songbox/pods/list/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var controller = _ember.default.inject.controller,
      readOnly = _ember.default.computed.readOnly;
  exports.default = _ember.default.Controller.extend({
    listsController: controller('lists'),

    editMode: readOnly('listsController.editMode')
  });
});