define('songbox/services/settings', ['exports', 'ember', 'songbox/local-settings-interface'], function (exports, _ember, _localSettingsInterface) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;


  /**
    Creates a setting being stored
    @private
    @method setting
  */
  function setting(name) {
    return computed({
      get: function get() /*key*/{
        return this.get('lsi.settings.' + name);
      },
      set: function set(_key, value) {
        this.set('lsi.settings.' + name, value);
        return value;
      }
    });
  }

  exports.default = _ember.default.Service.extend({

    nightMode: setting('nightMode'),
    showChords: setting('showChords'),
    showHeader: setting('showHeader'),

    init: function init() {
      this._super.apply(this, arguments);
      var lsi = _localSettingsInterface.default.create({
        serializer: 'json',
        adapter: _ember.default.testing ? 'local-memory' : 'local-storage',
        prefix: 'songbox/'
      });
      this.set('lsi', lsi);
    }
  });
});