define('songbox/pods/lists/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model() {
      return this.store.findAll('list');
    },


    actions: {
      select: function select(list) {
        return this.transitionTo('list', list);
      },
      remove: function remove(list) {
        return list.destroyRecord();
      }
    }
  });
});