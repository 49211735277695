define('songbox/pods/components/item-list/list-items/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ItemsComponent = _ember.default.Component.extend({
    models: [],
    group: null
  });

  ItemsComponent.reopenClass({
    positionalParams: ['models', 'group']
  });

  exports.default = ItemsComponent;
});