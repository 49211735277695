define('songbox/pods/components/songs-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    songs: [],

    actions: {
      selectSong: function selectSong(song) {
        this.get('onSelect')(song);
      },
      removeSong: function removeSong(song) {
        this.get('onRemove')(song);
      }
    }
  });
});