define('songbox/pods/list/route', ['exports', 'ember', 'songbox/mixins/routes/model-changeset', 'songbox/mixins/routes/dirty-changeset', 'songbox/validations/list'], function (exports, _ember, _modelChangeset, _dirtyChangeset, _list) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  exports.default = _ember.default.Route.extend(_modelChangeset.default, _dirtyChangeset.default, {
    flashMessages: service(),

    validator: _list.default,

    model: function model(params) {
      var _this = this;

      return this.store.find('list', params.list_id).catch(function () /*err*/{
        _this.get('flashMessages').warning('Could not retrieve List with ID ' + params.list_id);
      });
    },
    afterModel: function afterModel(model) {
      if (!model) {
        this.transitionTo('lists');
      }
    },

    actions: {
      save: function save(changeset) {
        var _this2 = this;

        changeset.save().then(function (list) {
          _this2.transitionTo('list', list);
        });
      },
      select: function select(list, item, index) {
        this.transitionTo('list.item', list, index + 1);
      },
      show: function show(list) {
        this.transitionTo('list', list);
      },
      remove: function remove(item) {
        item.destroyRecord();
      },
      reorder: function reorder(itemModels, draggedModel) {
        var _this3 = this;

        var currentItem = this.modelFor('list.item');
        var position = itemModels.indexOf(draggedModel);
        draggedModel.setProperties({ position: position });
        return draggedModel.save().then(function () {
          if (currentItem) {
            var currentPos = itemModels.indexOf(currentItem);
            return _this3.transitionTo('list.item', currentPos + 1);
          }
        });
      },
      add: function add(list, song) {
        var item = this.store.createRecord('list-item', {
          list: list,
          song: song
        });
        return item.save();
      }
    }
  });
});